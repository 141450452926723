import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import PopAddMap from "../components/PopAddMap";

const Maps = () => {

    const [maps, setMaps] = useState([]);
    const [popAddMap, setPopAddMap] = useState(false);
    const navigate = useNavigate();


    

    const onAddMap = (e, map_id) => {
        setPopAddMap(false);
        console.log(map_id);
        navigate(`/map/${map_id}`);

    }

    const onDeleteMap = (map_id) => {
        if (!window.confirm("Are you sure, you want to delete the map")) {
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/map/${map_id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((res) => {
                const map_index = maps.findIndex((map) => map.map_id === map_id);
                const s = [...maps];
                s.splice(map_index, 1);
                setMaps(s);
            });
        }


        const onOpenAddMapPop = (e) => {
            setPopAddMap(true);
        }

        const onCloseAddMapPop = (e) => {
            setPopAddMap(false);
        }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/map`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setMaps(json.maps);
            });
    }, []);

    return(
        <>
        <div className="content-header pb-6 flex justify-between">
                    <div className="content-header-label">Maps</div>
                    <div className="content-header-actions">
                        <Button onClick={onOpenAddMapPop}>Add New Map</Button>
                    </div>
                </div>
                <div className="content-body">

                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="font-bold p-2 border-b text-left w-2">No</th>
                                <th className="font-bold p-2 border-b text-left w-full">Map Name</th>
                                <th className="font-bold py-2 px-4 border-b text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {maps.map((map, i) => <tr key={map.map_id}>
                                <td className="p-2 border-b text-left">{i + 1}</td>
                                <td className="p-2 border-b text-left">{map.map_name}</td>
                                <td className="py-2 px-4 border-b text-left"> <Link to={`/map/${map.map_id}`}>Edit</Link> / <Button onClick={(e) => onDeleteMap(map.map_id)}>Delete</Button> </td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>
                {popAddMap && <PopAddMap onAddMap={onAddMap} onCloseAddMapPop={onCloseAddMapPop} />}
        </>
    )
}

export default Maps;