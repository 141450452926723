import { Button, TextField } from "@mui/material"

import "./../styles/PopAddSpace.scss";
import { useState } from "react";

const PopAddSpace = (props) => {

    const [spaceName, setSpaceName] = useState("");

    const onCloseAddSpacePop = (e) => {
        props.onCloseAddSpacePop(e);
    }

    const onAddSpace = (e) => {

        if (!spaceName) {
            return 0;
        }

        console.log(spaceName);



        fetch(`${process.env.REACT_APP_API_URL}/space`, {
            method: "POST",
            body: JSON.stringify({ space_name: spaceName }),
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((res) => res.json())
        .then(json => {
            props.onAddSpace(e, json.space_id);
        }).catch(err => {
            console.log(err)
        })
        //
    }

    const onSpaceNameEntered = (e) => {
        const space_name = e.target.value;

        setSpaceName(space_name);
    }

    return (
        <>
            <div className="pop-background">

            </div>

            <div className="pop-container">
                <div className="pop-header">
                    Add New Space
                </div>
                <div className="pop-content">
                    <TextField
                        label="Space Name"
                        value={spaceName}
                        onChange={onSpaceNameEntered}
                    />
                </div>
                <div className="pop-footer">
                    <Button onClick={onAddSpace}>Add</Button>
                    <Button onClick={onCloseAddSpacePop}>Cancel</Button>
                </div>
            </div>
        </>
    )
}

export default PopAddSpace;