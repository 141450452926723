import { Button, TextField } from "@mui/material";
import "./../styles/PopAddEditHistory.scss";
import { ASSETS_PATH } from "../constants";
import { useEffect, useState } from "react";

const PopAddEditHistory = ({era, onHistoryDone, onUpdateHistory, uploadAsset}) => {


    const [cEra, setCEra] = useState({year:"", asset_url:""});

    useEffect(() => {
        setCEra({...era});
       
    }, []);

    const onUpdateAndCloseHistory = () => {
        onUpdateHistory({...cEra});
    }

    const onChangeYear = (e) => {
        const y = e.target.value;
        setCEra({...cEra, year:y});
        
    }


    const onRemoveImage = (e) => {
        setCEra({...cEra, asset_url:""});
    }

    const onChangeUploadImage = (e) => {
        const filename = uploadAsset(e, (fileName) => { setCEra({...cEra, asset_url:fileName}); });
    }

    return(
        <>
        <div className="pop-background"></div>
        <div className="pop-history-container">
            <div className="pop-header">
                History Popup
            </div>
            <div className="pop-content">
                <form>
                    <TextField className="full"
                    label='Year'
                    value={cEra.year}
                    onChange={onChangeYear}
                    />
                    <br/>
                    <br/>
                    {cEra.asset_url && <>
                    <img src={`${ASSETS_PATH}/${cEra.asset_url}`} width={500} />
                    <Button onClick={onRemoveImage}>Remove Image</Button>
                    </>}
                    {!cEra.asset_url && 
                    <>
                    <img src={`https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg`} width={500} />
                    
                    <Button
                    variant="contained"
                    component="label"
                    >
                        Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={onChangeUploadImage}
                        />
                        </Button>
                    </>
                    }
                </form>
            </div>
            <div className="pop-footer">
                <Button variant="contained" onClick={onUpdateAndCloseHistory}>Update and Close</Button>
                <Button onClick={onHistoryDone}>Close</Button>
            </div>
        </div>
        </>
    )
    
}

export default PopAddEditHistory;