import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import PopAddSpace from "../components/PopAddSpace";


const Spaces = () => {

    const [spaces, setSpaces] = useState([]);
    const [popAddSpace, setPopAddSpace] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/space`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setSpaces(json.spaces);
            });
    }, []);


    const onAddSpace = (e, space_id) => {
        setPopAddSpace(false);
        console.log(space_id);
        navigate(`/space/${space_id}`);

    }

    const onDeleteSpace = (space_id) => {
        if (!window.confirm("Are you sure, you want to delete the space")) {
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/space/${space_id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((res) => {
                const space_index = spaces.findIndex((space) => space.space_id === space_id);
                const s = [...spaces];
                s.splice(space_index, 1);
                setSpaces(s);
            });
        }


        const onOpenAddSpacePop = (e) => {
            setPopAddSpace(true);
        }

        const onCloseAddSpacePop = (e) => {
            setPopAddSpace(false);
        }

        return (
            <>
                <div className="content-header pb-6 flex justify-between">
                    <div className="content-header-label">Spaces</div>
                    <div className="content-header-actions">
                        <Button onClick={onOpenAddSpacePop}>Add New Space</Button>
                    </div>
                </div>
                <div className="content-body">

                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="font-bold p-2 border-b text-left w-2">No</th>
                                <th className="font-bold p-2 border-b text-left w-full">Space Name</th>
                                <th className="font-bold py-2 px-4 border-b text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {spaces.map((space, i) => <tr key={space.space_id}>
                                <td className="p-2 border-b text-left">{i + 1}</td>
                                <td className="p-2 border-b text-left">{space.space_name}</td>
                                <td className="py-2 px-4 border-b text-left"> <Link to={`/space/${space.space_id}`}>Edit</Link> / <Button onClick={(e) => onDeleteSpace(space.space_id)}>Delete</Button> </td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>
                {popAddSpace && <PopAddSpace onAddSpace={onAddSpace} onCloseAddSpacePop={onCloseAddSpacePop} />}

            </>
        )
    }

    export default Spaces;