import { Button, TextField } from "@mui/material"

import "./../styles/PopAddSpace.scss";
import { useState } from "react";

const PopAddMap = (props) => {

    const [mapName, setMapName] = useState("");

    const onCloseAddMapPop = (e) => {
        props.onCloseAddMapPop(e);
    }

    const onAddMap = (e) => {

        if (!mapName) {
            return 0;
        }

        console.log(mapName);



        fetch(`${process.env.REACT_APP_API_URL}/map`, {
            method: "POST",
            body: JSON.stringify({ map_name: mapName }),
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((res) => res.json())
        .then(json => {
            props.onAddMap(e, json.map_id);
        }).catch(err => {
            console.log(err)
        })
        //
    }

    const onMapNameEntered = (e) => {
        const map_name = e.target.value;

        setMapName(map_name);
    }

    return (
        <>
            <div className="pop-background">

            </div>

            <div className="pop-container">
                <div className="pop-header">
                    Add New Map
                </div>
                <div className="pop-content">
                    <TextField
                        label="Map Name"
                        value={mapName}
                        onChange={onMapNameEntered}
                    />
                </div>
                <div className="pop-footer">
                    <Button onClick={onAddMap}>Add</Button>
                    <Button onClick={onCloseAddMapPop}>Cancel</Button>
                </div>
            </div>
        </>
    )
}

export default PopAddMap;