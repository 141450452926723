import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import CircularWithValueLabel from '../components/CircularWithValueLabel';
import axiosClient from '../axios-client';
import { ASSETS_PATH } from '../constants';
import AddHotspotsOnMap from '../components/AddHotspotsOnMap';
import sample_image from './../assets/map_1.png';





const Map = () => {

    const [mapPop, setMapPop] = useState(false);
    const { map_id } = useParams();
    const [mapData, setMapData] = useState({});
    const [spaces, setSpaces] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const onCloseMapPop = () => {
        setMapPop(false);
    }


    const onUpdateAndClosePop = (hotspotsData) => {
        let data = [...hotspotsData.map(h => { return {...h} })]
        console.log(data);
        setMapData( d => {
            return { ...d, map_hotspots_2d: data}
        })
        setMapPop(false);
    }

    const onOpenMapPop = () => {
        setMapPop(true);
    }

    const onChangeUploadMapImage = (e) => {
        uploadAsset(e, (fileName) => { 
            setMapData(d => {
                return {...d, map_image_2d:fileName}
            }) 
        });
    }

    const onRemoveImage = () => {
        setMapData(d => {
            return {...d, map_image_2d:""}
        })
    }

    const onSaveMapData = () => {

        let payload = {...mapData, map_hotspots_2d:JSON.stringify(mapData.map_hotspots_2d)}


        fetch(`${process.env.REACT_APP_API_URL}/map`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((res) => res.json())
            .then(json => {
                alert(json.message);
            }).catch(err => {
                console.log(err)
            })
    }

    const uploadAsset = async (e, cb) => {
        let filename = "file.jpg";
        setUploading(true);

        let formData = new FormData();
        formData.append("file", e.target.files[0]);

        axiosClient.post(`${process.env.REACT_APP_API_URL}/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: event => {
                console.log(event.loaded)
                setProgress(100 * event.loaded / event.total);
            }
        }).then((res) => {
            //console.log(res);
            cb(res.data.filename);
            setUploading(false);
        })

    }

const onChangeMapName = (e) => {

    let name = e.target.value;

    setMapData(d => {
        return {...d, map_name: name}
    })

}
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/map/${map_id}`, { method: 'GET' })
        .then((res) => res.json())
        .then((json) => {
            console.log(json);
            setMapData(json.map);
        });

        fetch(`${process.env.REACT_APP_API_URL}/space`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                setSpaces(json.spaces);
            });
    }, [])

    return (
        <>
            <div className="content-body">
                <form>
                    <TextField className='textfield w-full' value={mapData.map_name} onChange={onChangeMapName}/>
                    <br/>
                    <br/>
                    {mapData.map_image_2d && <>
                        <img src={`${ASSETS_PATH}/${mapData.map_image_2d}`} style={{width:'400px'}}/>
                        <br/>
                        <br/>
                    </>
                    }
                    {!mapData.map_image_2d && <Button
                        variant="contained"
                        component="label"
                        style={{ marginRight: "10px" }}
                    >
                        *Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={onChangeUploadMapImage}
                        />
                        </Button>}

                        {mapData.map_image_2d && 
                        <>
                        <Button variant='contained' onClick={onRemoveImage}>Remove Image</Button>
                    <Button variant='contained' onClick={onOpenMapPop}>Place Hotspots</Button>
                        </>}
                    <br/>
                    <br/>
                    <hr/>
                    <br/>

                    <Button variant='contained' onClick={onSaveMapData}>Save</Button>    
                   
                </form>
            </div>
            {mapPop && <AddHotspotsOnMap onCloseMapPop={onCloseMapPop} mapData={mapData} spaces={spaces} onUpdateAndClosePop={onUpdateAndClosePop}/>}
            {uploading && <CircularWithValueLabel progress={progress} />}
        </>

    )
}

export default Map;