// MUI Components
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';
import PopAddEditHotspot from '../components/PopAddEditHotspot';

import { ASSETS_PATH } from '../constants';
import PopAddEditHistory from '../components/PopAddEditHistory';
import { useParams } from 'react-router-dom';
import CircularWithValueLabel from '../components/CircularWithValueLabel';
import axiosClient from '../axios-client';


const Space = () => {

    const [editHotspot, setEditHotspot] = useState(null)
    const [editHistory, setEditHistory] = useState(null);
    const { space_id } = useParams();
    const [spaceId, setSpaceId] = useState();
    const [spaces, setSpaces] = useState([]);
    const [spaceName, setSpaceName] = useState("");
    const [spaceImage, setSpaceImage] = useState("");
    const [spaceVideo, setSpaceVideo] = useState("");
    const [hotspots, setHotspots] = useState([]);
    const [history, setHistory] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const videoRef = useRef();



    const onChangeUploadSpaceImage = (e) => {
        const filename = uploadAsset(e, (fileName) => { setSpaceImage(fileName) });
    }

    const onChangeUploadSpaceVideo = (e) => {
        const filename = uploadAsset(e, (fileName) => { 
            setSpaceVideo("");
            setTimeout(()=>{setSpaceVideo(fileName)}, 1000);
        });
        
        
    }


    useEffect(() => {
        // console.log(space_id);
        fetch(`${process.env.REACT_APP_API_URL}/space`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setSpaces(json.spaces);
                setPageData(json.spaces.find(space => space.space_id == space_id));
            });
    }, [])



    const setPageData = (space) => {

        setSpaceId(space.space_id);
        setSpaceName(space.space_name);
        setSpaceImage(space.space_image);
        setSpaceVideo(space.space_video);
        // console.log(JSON.parse(space.space_hotspots));

        if (space.space_hotspots) {

            setHotspots(space.space_hotspots);
        }

        if (space.space_history) {

            setHistory(space.space_history);
        }

    }


    useEffect(() => {
        //console.log(spaceImage);
    }, [spaceImage]);


    const nextHotspotId = () => {

        if (hotspots.length === 0) return 1;

        const ids = [];

        hotspots.forEach(ht => {
            ids.push(ht.hotspot_id);
        });

        //console.log("ids", ids);
        return Math.max(...ids) + 1;
    }


    const nextEraId = () => {

        if (history.length === 0) return 1;

        const ids = [];

        history.forEach(ht => {
            ids.push(ht.hotspot_id);
        });

        //console.log("ids", ids);
        return Math.max(...ids) + 1;
    }

    /* const uploadAsset = async (e, cb) => {
        let filename = "file.jpg";

        let formData = new FormData();
        formData.append("file", e.target.files[0]);

        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/upload-file`, {
                method: "POST",
                body: formData,

            })

            const filename = await result.json();

            cb(filename.filename);

        } catch (err) {
            console.log(err);

        }

    } */


    const uploadAsset = async (e, cb) => {
        let filename = "file.jpg";
        setUploading(true);

        let formData = new FormData();
        formData.append("file", e.target.files[0]);

        axiosClient.post(`${process.env.REACT_APP_API_URL}/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: event => {
                console.log(event.loaded)
                setProgress(100 * event.loaded / event.total);
            }
        }).then((res) => {
            //console.log(res);
            cb(res.data.filename);
            setUploading(false);
        })

    }


    const onChangeSpaceName = (e) => {
        const s = e.target.value;
        setSpaceName(s);
    }

    const onEditHotspot = (hotspot) => {

        setEditHotspot({ ...hotspot });

    }

    const onDeleteHotspot = (index) => {
        const h = [...hotspots];
        h.splice(index, 1);
        setHotspots(h);
    }

    const updateHotspot = (hotspot) => {
        //console.log(hotspot);
        let hsps = [...hotspots];
        let index = hotspots.findIndex((h) => h.hotspot_id === hotspot.hotspot_id);

        if (index < 0) {
            hsps.push(hotspot);
        } else {
            hsps[index] = hotspot;
        }


        setHotspots(hsps);
        setEditHotspot(null);
        //console.log(hotspots);
    }


    const onDone = () => {
        setEditHotspot(null);
    }

    const onAddHotspot = () => {
        const h = {
            hotspot_id: nextHotspotId(),
            hotspot_name: "",
            hotspot_type: "none",
            hotspot_data: {
                hotspot_linked_to: 0,
                title: "",
                description: "",
                gallery: [],
                artefact: {
                    model_url: "",
                    model_map_url: ""
                }
            }
        };
        console.log(h);
        setEditHotspot(h)
    }


    const onEditHistory = (era) => {
        setEditHistory(era);
    }

    const onDeleteHistory = (index) => {
        const h = [...history];
        h.splice(index, 1);
        setHistory(h);
    }

    const onHistoryDone = () => {
        setEditHistory(null);
    }

    const onUpdateHistory = (era) => {

        let eras = [...history];
        let index = eras.findIndex((e) => e.era_id === era.era_id);

        if (index < 0) {
            eras.push(era);
        } else {
            eras[index] = era;
        }


        setHistory(eras);
        setEditHistory(null);

    }


    const onClickAddNewEra = (e) => {
        setEditHistory({ era_id: nextEraId(), asset_url: "", year: "" });
    }


    const onSaveSpace = () => {

        const payload = {
            space_id: space_id,
            space_name: spaceName,
            space_image: spaceImage,
            space_video: spaceVideo,
            space_hotspots: JSON.stringify(hotspots),
            space_history: JSON.stringify(history)
        }

        fetch(`${process.env.REACT_APP_API_URL}/space`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((res) => res.json())
            .then(json => {
                alert(json.message);
            }).catch(err => {
                console.log(err)
            })
    }

    const onRemoveSpaceImage = () => {
        setSpaceImage("");
    }


    const onRemoveSpaceVideo = () => {
        setSpaceVideo("");
    }

    return (
        <>
            <div className="content-header pb-6">
                Space Name
            </div>
            <div className="content-body">
                <form>
                    <TextField
                        className='textfield w-full'
                        id="space-name"

                        value={spaceName}
                        onChange={onChangeSpaceName}
                    />
                    <br />
                    <br />
                    <img src={`${ASSETS_PATH}/${spaceImage}`} width={400} />
                    <br/>
                    {!spaceImage && <Button
                        variant="contained"
                        component="label"
                        style={{ marginRight: "10px" }}
                    >
                        *Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={onChangeUploadSpaceImage}
                        />
                    </Button>}
                    {spaceImage && <Button onClick={onRemoveSpaceImage}>Remove Image</Button>}
<br/>
<br/>
                    {false && spaceVideo && <video width={400} controls autoPlay id="spaceVideo">
                        <source src={`${ASSETS_PATH}/${spaceVideo}`} mime_type="video/mp4" />
                    </video>}
<br/>
                    {false && !spaceVideo && <Button
                        variant="contained"
                        component="label"
                    >
                        Upload Video
                        <input
                            type="file"
                            hidden
                            onChange={onChangeUploadSpaceVideo}
                        />
                    </Button>}
                    {false && spaceVideo && <Button onClick={onRemoveSpaceVideo}>Remove Video</Button>}
                    <br />
                    <br />
                    <h2>Add Hotspots </h2>
                    <br />
                    <Button variant="contained" onClick={onAddHotspot}>Add Hotspot</Button>
                    <br />
                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="font-bold p-2 border-b text-left w-2">No</th>
                                <th className="font-bold p-2 border-b text-left">Hotspot Name</th>
                                <th className="font-bold p-2 border-b text-left">Hotspot Type</th>
                                <th className="font-bold py-2 px-4 border-b text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {hotspots.map((hotspot, index) => <tr key={index}>
                                <td className="p-2 border-b text-left">{index + 1}</td>
                                <td className="p-2 border-b text-left">{hotspot.hotspot_name}</td>
                                <td className="p-2 border-b text-left">{hotspot.hotspot_type[0].toUpperCase() + hotspot.hotspot_type.slice(1)}</td>
                                <td className="py-2 px-4 border-b text-left">
                                    <Button onClick={() => { onEditHotspot(hotspot) }}>Edit</Button>
                                    <Button onClick={() => {onDeleteHotspot(index)}}>Delete</Button>
                                </td>
                            </tr>)}

                        </tbody>
                    </table>
                    <br />
                    Hitory
                    <br />
                    <Button onClick={onClickAddNewEra}>Add New Year</Button>
                    <br />
                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="font-bold p-2 border-b text-left w-2">Year</th>
                                <th className="font-bold p-2 border-b text-left">Preview</th>
                                <th className="font-bold py-2 px-4 border-b text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {history.map((era, index) => <tr key={era.year}>
                                <td className="p-2 border-b text-left">{era.year}</td>
                                <td className="p-2 border-b text-left"><img src={`${ASSETS_PATH}/${era.asset_url}`} width={150} /></td>
                                <td className="py-2 px-4 border-b text-left">
                                    <Button onClick={() => { onEditHistory(era) }}>Edit</Button>
                                    <Button onClick={() => { onDeleteHistory(index)}}>Delete</Button>
                                </td>
                            </tr>)}

                        </tbody>
                    </table>
                    <br />
                    <Button onClick={onSaveSpace}>Save</Button>
                    <Button>Cancel</Button>
                </form>

                {
                    editHotspot &&
                    <PopAddEditHotspot
                        updateHotspot={updateHotspot}
                        hotspot={editHotspot}
                        onDone={onDone}
                        spaces={spaces}
                        spaceImage={spaceImage}
                        uploadAsset={uploadAsset}
                    />}

                {
                    editHistory &&
                    <PopAddEditHistory
                        era={editHistory}
                        onHistoryDone={onHistoryDone}
                        onUpdateHistory={onUpdateHistory}
                        uploadAsset={uploadAsset}
                    />
                }
            </div>
            {uploading && <CircularWithValueLabel progress={progress} />}
        </>
    )
}

export default Space;