import './../styles/AddHotspotsOnMap.scss';
import sample_image from './../assets/map_1.png';
import { HiMapPin } from "react-icons/hi2";
import { useEffect, useState } from 'react';
import { Select, MenuItem, Button } from '@mui/material';
import { ASSETS_PATH } from '../constants';


let selectedHotspot = null;
let gHotspots = [];
let mapHeight = 0;

const AddHotspotsOnMap = ({onCloseMapPop, mapData, spaces, onUpdateAndClosePop}) => {

    const [hotspots, setHotspots] = useState(mapData.map_hotspots_2d);
    const [sceneID, setSceneID] = useState(0);
    const [activeHotspot, setActiveHotspot] = useState(null);
    const [activeHotspotIndex, setActiveHotspotIndex] = useState(null);

    const onHotspotDown = (e, index) => {

        updateHotspots();
        
        let eles = document.getElementsByClassName('hotspot');
        setActiveHotspotIndex(index);
        for (let i = 0; i < eles.length; i++) {
            //eles[i].style.fill = 'black';
        }

        selectedHotspot = e.currentTarget;
        setActiveHotspot(selectedHotspot);
        
        //selectedHotspot.style.fill = 'red';
        document.addEventListener('mousemove', onHotspotMove);
        mapHeight = document.getElementById('map').offsetHeight;

        setSceneID(parseInt(selectedHotspot.getAttribute('scene_id')));
    }


    const onHotspotMove = (e) => {
        const left = parseInt(selectedHotspot.style.left);
        const top = parseInt(selectedHotspot.style.top);

        let newLeft = e.movementX + left;

        newLeft = newLeft < 0 ? 0 : newLeft;
        newLeft = newLeft > 800 ? 800 : newLeft;

        let newTop = e.movementY + top;

        newTop = newTop < 0 ? 0 : newTop;
        newTop = newTop > mapHeight ? mapHeight : newTop;

        selectedHotspot.style.left = newLeft + 'px';
        selectedHotspot.style.top = newTop + 'px';
    }


    const onAddNewHotspot = () => {
        updateHotspots();

        setHotspots(prev => {
           return [...prev.map(h => {
                return {...h}
            }), { x: 125, y:125, scene_id:0}]
        })
    }

const onChangeSceneID = (e) => {
    
    const id = e.target.value;

    setSceneID(id);

    selectedHotspot.setAttribute('scene_id', id);

    updateHotspots();
}

const onDeleteHotspot = () => {


    setHotspots(prev => {
        return prev.filter((_, i) => i!==activeHotspotIndex)
    });

    selectedHotspot = null;
    setActiveHotspot(selectedHotspot);
    setActiveHotspotIndex(null);
}   

const onUpdateAndClose = () => {

    updateHotspots();
    onUpdateAndClosePop(gHotspots);
}
    
    useEffect(() => {

        document.addEventListener('mouseup', () => { 
            document.removeEventListener('mousemove', onHotspotMove);
            
        });

    });


    const updateHotspots = () => {
        const hs = [];
        

        let eles = document.getElementsByClassName('hotspot');

        for (let i = 0; i < eles.length; i++) {

            let h = {

                x: parseInt(eles[i].style.left),
                y: parseInt(eles[i].style.top),
                scene_id: parseInt(eles[i].getAttribute('scene_id'))
            }; 

            hs.push(h);

        }

        gHotspots = hs;

        setHotspots(prev => hs);
    }


    return (
        <>
            <div className="pop-map-background"></div>
            <div className='pop-map'>
                <div className='pop-header'>
                    <div className='map-name'>
                        Ground Floor
                    </div>
                    <div>
                        {activeHotspot && <Select label="Select space" value={sceneID} onChange={onChangeSceneID}>
                            <MenuItem value={0}>Select Space</MenuItem>
                            {spaces.map((s, index) => <MenuItem key={index} value={s.space_id}>{s.space_name}</MenuItem>)}
                           
                        </Select>}
                    </div>
                </div>
                <div className='pop-body'>
                    <div className='hotspots-body'>
                        <img src={`${ASSETS_PATH}/${mapData.map_image_2d}`} id="map" />
                        {
                           hotspots.map(
                            (h, index) => {

                                return (<HiMapPin key={index} hindex={index} className='hotspot' onMouseDown={(e) => {onHotspotDown(e, index)}} style={{ top: h.y + 'px', left: h.x + 'px', fill: activeHotspotIndex === index ? 'red':'black' }} scene_id={h.scene_id}/>)
                            }
                           )
                        }
                        
                    </div>
                </div>
                <div className='pop-footer'>
                <Button onClick={onAddNewHotspot}>Place New Hotspot</Button>
                {activeHotspot && <Button onClick={onDeleteHotspot}>Delete Selected Hotspot</Button>}
                <Button onClick={onUpdateAndClose}>Update and Close</Button>
                <Button variant='contained' onClick={onCloseMapPop}>Close</Button>
                </div>
            </div>
        </>
    )
}

export default AddHotspotsOnMap;